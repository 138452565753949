<template>
	<div>
  <cv-header aria-label="Carbon header">
    <cv-header-menu-button aria-label="Header menu" aria-controls="side-nav"/>
    <cv-skip-to-content href="#main-content">
      Skip to content
    </cv-skip-to-content>
		<router-link :to="$router.currentRoute.path == '/' ? '/' : '/home'" style="text-decoration: none; color: inherit;">
			<cv-header-name prefix="Brainrise">
				Dashboard
			</cv-header-name>
		</router-link>
		<template v-slot:header-global>
			<cv-header-global-action
      aria-label="Logout"
      @click="logoutAction"
      aria-controls="user-panel"
      label="Log out"
      tipPosition="bottom"
      tipAlignment="center"
			v-if="$store.state.loggedIn"
      >
      <Login20 />
    </cv-header-global-action>
		</template>
    <template v-slot:left-panels>
			<div class="main-wrapper">
				<cv-side-nav id="side-nav" v-if="$store.state.showLeftPanel">
					<cv-side-nav-items>
						<cv-side-nav-menu title="Projects/Patients" :expanded="$router.currentRoute.path.includes('/projects') || $router.currentRoute.path.includes('/patients') ? true : false">
							<router-link to="/patients/lookup" style="text-decoration: none; color: inherit;">
								<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/patients/lookup') ? true : false">
									Patient Lookup
								</cv-side-nav-menu-item>
							</router-link>
							<router-link to="/projects/lookup" style="text-decoration: none; color: inherit;">
								<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/projects/lookup') ? true : false">
									Project Lookup
								</cv-side-nav-menu-item>
							</router-link>
							<router-link to="/projects/create" style="text-decoration: none; color: inherit;">
								<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/projects/create') ? true : false">
									Create Project
								</cv-side-nav-menu-item>
							</router-link>
						</cv-side-nav-menu>
						<cv-side-nav-menu title="EEG" :expanded="$router.currentRoute.path.includes('/eeg')  ? true : false">
							<router-link to="/eeg/downsample" style="text-decoration: none; color: inherit;">
								<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/eeg/downsample') ? true : false">
									Downsampling
								</cv-side-nav-menu-item>
							</router-link>
						</cv-side-nav-menu>
						<cv-side-nav-menu title="BLT" :expanded="$router.currentRoute.path.includes('/blt')  ? true : false">
							<router-link to="/blt/active" style="text-decoration: none; color: inherit;">
								<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/blt/active') ? true : false">
									Active Devices
								</cv-side-nav-menu-item>
							</router-link>
						</cv-side-nav-menu>
						<cv-side-nav-menu title="Natus" :expanded="$router.currentRoute.path.includes('/natus') ? true : false">
							<router-link to="/natus/active" style="text-decoration: none; color: inherit;">
								<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/natus/active') ? true : false">
									Active Devices
								</cv-side-nav-menu-item>
							</router-link>
						</cv-side-nav-menu>
						<router-link to="/services" style="text-decoration: none; color: inherit;">
							<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/services') ? true : false">
								Services
							</cv-side-nav-menu-item>
						</router-link>
						<router-link to="/auth" style="text-decoration: none; color: inherit;">
							<cv-side-nav-menu-item :active="$router.currentRoute.path.includes('/auth') ? true : false">
								Authentication
							</cv-side-nav-menu-item>
						</router-link>
					</cv-side-nav-items>
				</cv-side-nav>
			</div>
    </template>
    <!-- <template v-slot:right-panels v-if="showLeftPanel"> </template> -->
  </cv-header>
	<cv-content class="main" :style="windowWidth > 1050 && $store.state.showLeftPanel ? 'margin-left: 16rem' : 'margin-left: 0rem'">  
		<router-view></router-view>
	</cv-content>
</div>
</template>

<script>
import Login20 from '@carbon/icons-vue/es/login/20';

export default {
  name: "BrainriseDashboard",
	components: {
		Login20
	},
  data() {
    return {
			clickedRoute: this.$router.currentRoute.path,
			isSidebarOpen: true,
			currentRoute: this.$router.currentRoute.path,
			error: false,
			errorTitle: "Unauthorized",
			errorMessage: "You need to sign in to access this page.",
		};
  },
	computed: {
		// mainStyle() {
		// 	console.log(window.innerWidth)
    //   return {
    //     marginLeft: window.innerWidth > 500 ? '16rem' : '0rem'
		// 	}
    // }
	},
  methods: {
		toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
    },
		onResize() {
			console.log(window.innerHeight)
      this.windowHeight = window.innerHeight
    },
		async logoutAction() {
			this.$store.commit("setLeftPanel", false)
			this.$store.commit("loggedIn", false);
			this.$store.commit("setToken", "")
			this.$cookies.remove("token")
			await fetch("https://api.ehealth.thewcl.com/v2/dashboard/logout?token=" + this.$store.state.token)
			this.$router.push("/")
		},
		doClose() {
			this.error = false
		}
	},
	async updated (){
		/*if(this.$store.state.loggedIn == false && this.$router.currentRoute.path != "/") {
			if(this.$cookies.get("token")) {
				this.$store.commit("setToken", this.$cookies.get("token"))
				this.$store.commit("loggedIn", true)
				this.$store.commit("setLeftPanel", true)
				if(this.$router.currentRoute.path == "/" || this.$router.currentRoute.path == "") {
					this.$router.push("/home")
				}
			}
			else {
				this.$router.push("/")
			}
		}
		else {
			if(this.$router.currentRoute.path != "/") {
				var validationRequest = await fetch("https://api.ehealth.thewcl.com/v2/dashboard/token?token=" + this.$store.state.token)
				var validationResponse = await validationRequest.json()
				if(validationResponse.status == "invalid") {
					this.$router.push("/")
					this.$store.commit("setToken", "")
				}
			}
			else {
				// if query parameter r is undefined
				if(this.$route.query.r == undefined && this.$store.state.loggedIn == true) {
					this.$router.push("/home")
				}
				else {
					if(this.$store.state.loggedIn == true) {
						this.$router.push(this.$route.query.r)
					}
					// if query parameter r is not undefined
				}
			}
		}*/
	},
	async created() {
		if(this.$cookies.get("token")) {
			console.log("Validating existing token...")
			this.$store.commit("setToken", this.$cookies.get("token"))
			var validationRequest = await fetch("https://api.ehealth.thewcl.com/v2/dashboard/token?token=" + this.$store.state.token)
			var validationResponse = await validationRequest.json()
			if(validationResponse.status == "invalid") {
				console.log("Token invalid, prompting for login")
				this.$store.commit("setToken", "")
				if(this.$router.currentRoute.path != "/") {
					this.$router.push("/?r=" + this.$router.currentRoute.path)
				}
				else {
					if(this.$route.query.r != undefined) {
						this.$router.push("/?r=" + this.$route.query.r)
					}
					else {
						this.$router.push("/")
					}
				}
			}
			else {
				console.log("Token valid, logging in")
				this.$store.commit("loggedIn", true)
				if(!this.$router.currentRoute.path.startsWith("/dashboard")) {
					this.$store.commit("setLeftPanel", true)
				}
				if(this.$router.currentRoute.path == "/") {
					this.$router.push("/home")
				}
			}
		}
		else {
			console.log("No token found, prompting for login")
			this.$store.commit("setToken", "")
			this.$store.commit("loggedIn", false)
			this.$store.commit("setLeftPanel", false)

			// console.log(this.$router.currentRoute.path)
			if(this.$router.currentRoute.path != "/") {
				this.$router.push("/?r=" + this.$router.currentRoute.path)
			}
			else {
				if(this.$route.query.r != undefined) {
					this.$router.push("/?r=" + this.$route.query.r)
				}
				else {
					this.$router.push("/")
				}
			}
		}

		// if(this.$router.currentRoute.path == "/" && this.$store.state.loggedIn == true) {
		// 	console.log("Logged in, redirecting...")
		// 	if(this.$route.query.r != undefined) {
		// 		this.$router.push(this.$route.query.r)
		// 	}
		// 	else {
		// 		this.$router.push("/home")
		// 	}
		// }
		// else {
		// 	if(this.$router.currentRoute.path == "/" && this.$store.state.loggedIn == false) {
		// 		console.log("Not logged in")
		// 	}
		// 	if(this.$router.currentRoute.path != "/") {
		// 		console.log("Not on login page")
		// 	}
		// }
	}
};
</script>

<style>
.sample {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 5% auto;
}

.cv-text-input {
  margin: 30px 0;
}
</style>
