<template>
  <div class="home">
    <img alt="Brainrise logo" class="logo" src="../assets/brainrise.png">
    <h1>Brainrise Dashboard</h1>
		<p>Login below using your Grafana credentials to manage Brainrise systems.</p>
		<LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue'

export default {
  name: 'HomeView',
  components: {
    LoginForm
  },
	beforeCreate(){
		this.$store.commit("setLeftPanel", false)
	}
}
</script>

<style>
.home {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.logo {
	border-radius: 10%;
	margin-bottom: 1rem;
	height: 10rem;
}
</style>