<template>
	<div style="width: 40%">
		<cv-inline-notification v-if="error" 
				kind="error"
				:title="title"
				:sub-title="subTitle"
				@close="doClose"
				></cv-inline-notification>
		<cv-form v-on:submit.prevent="actionSubmit">
			<cv-text-input
				label="Username"
				v-model="username"
				:disabled="disabled"
				:password-visible="true"
				placeholder="Username/Email"
				>
			</cv-text-input>
			<cv-text-input
				label="Password"
				v-model="password"
				:disabled="disabled"
				type="password"
				:password-visible="false"
				placeholder="Password"
				>
			</cv-text-input>
			<cv-button>
				Login
			</cv-button>
		</cv-form>
	</div>	
</template>

<script>
export default {
	name: "LoginForm",
	data() {
		return {
			username: "",
			password: "",
			disabled: false,
			error: false,
			title: "Invalid Credentials",
			subTitle: "The username/password you entered were not valid.",
		};
	},
	methods: {
		async actionSubmit() {
			var result = await fetch(`https://api.ehealth.thewcl.com/v2/dashboard/validate?username=${this.username}&password=${this.password}`)
			var json = await result.json()
			console.log(json)

			if (json.status == "success") {
				this.$store.commit("setLeftPanel", true)
				this.$store.commit("loggedIn", true);
				this.$store.commit("setToken", json.token)
				this.$cookies.set("token", json.token)
				if(this.$route.query.r != undefined) {
					this.$router.push(this.$route.query.r)
				}
				else {
					this.$router.push("/home")
				}
			} else {
				this.error = true
				this.password = ""
			}
		},
		doClose() {
			this.error = false
		}
	}
}
</script>