import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'carbon-components/css/carbon-components.css';
import CarbonComponentsVue from '@carbon/vue/src/index';
import VueWindowSize from 'vue-window-size';
import VueCookies from 'vue-cookies'

Vue.use(CarbonComponentsVue);
Vue.use(VueWindowSize);
Vue.use(VueCookies, { expires: '2d'})
Vue.config.productionTip = false

const v = new Vue({
  router,
  store,
  render: h => h(App)
})


router.onReady(() => {
  v.$mount('#app');
});