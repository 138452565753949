import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
	{
		path: '/eeg/downsample',
		name: 'downsample-eeg',
		component: () => import(/* webpackChunkName: "downsampleEEG" */ '../views/DownsampleEEGView.vue')
	},
	{
		path: '/projects/lookup',
		name: 'lookup-projects',
		component: () => import(/* webpackChunkName: "lookupProjects" */ '../views/ProjectLookupView.vue')
	},
	{
		path: '/patients/lookup',
		name: 'lookup-patients',
		component: () => import(/* webpackChunkName: "lookupPatients" */ '../views/PatientLookupView.vue')
	},
	{
		path: '/projects/create',
		name: 'create-project',
		component: () => import(/* webpackChunkName: "createProject" */ '../views/CreateProjectView.vue')
	},
	{
		path: '/services',
		name: 'services',
		component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue')
	},
	{
		path: '/home',
		name: 'dashboard-home',
		component: () => import(/* webpackChunkName: "dashboard-home" */ '../views/DashboardHome.vue')
	},
	{
		path: '/dashboard/tremor/log/:id',
		name: 'tremor-log',
		component: () => import(/* webpackChunkName: "tremor-log" */ '../views/TremorLogView.vue')
	},
	{
		path: '/dashboard/artie/survey/:id',
		name: 'artie-survey',
		component: () => import(/* webpackChunkName: "artie-survey" */ '../views/ArtieSurveyView.vue')
	},
	{
		path: '/dashboard/questionnaire/:id',
		name: 'questionnaire',
		component: () => import(/* webpackChunkName: "questionnaire" */ '../views/QuestionnaireView.vue')
	},
	{
		path: '/blt/active',
		name: 'active-blts',
		component: () => import(/* webpackChunkName: "active-blts" */ '../views/BLTActiveDevicesView.vue')
	},
	{
		path: '/blt/device/:id',
		name: 'blt-device',
		component: () => import(/* webpackChunkName: "blt-device" */ '../views/BLTDeviceView.vue')
	},
	{
		path: '/auth',
		name: 'auth',
		component: () => import(/* webpackChunkName: "auth" */ '../views/AuthenticationView.vue')
	},
	{
		path: '/auth/:id',
		name: 'user-auth-manager',
		component: () => import(/* webpackChunkName: "user-auth-manager" */ '../views/AuthenticationUserView.vue')
	},
	{
		path: '/natus/active',
		name: 'active-natus',
		component: () => import(/* webpackChunkName: "active-natus" */ '../views/NatusActiveDevicesView.vue')
	},
	{
		path: '/natus/device/:id',
		name: 'natus-device',
		component: () => import(/* webpackChunkName: "natus-device" */ '../views/NatusDeviceView.vue')
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
