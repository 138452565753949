import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		showLeftPanel: true,
		loggedIn: false,
		user: null,
		token: ""
  },
  getters: {
		showLeftPanel: state => state.showLeftPanel,
  },
  mutations: {
		setLeftPanel(state, value) {
			state.showLeftPanel = value;
		},
		loggedIn(state, value) {
			state.loggedIn = value;
		},
		setToken(state, value) {
			state.token = value;
		},
		setUser(state, value) {
			state.user = value;
		}
  },
  actions: {

  },
  modules: {
  }
})
